import React from "react"
import { PageProps, graphql } from "gatsby"
import { AspectRatio, Grid, Heading, Link, Flex, Text, Divider, Box } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"
import { Button } from "@chakra-ui/button"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Tooltip } from "@chakra-ui/tooltip"


import Layout from "../components/layout"
import SimpleList from "../components/simple-list"
import { useState } from "react"

import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    useDisclosure,
    useMediaQuery
} from "@chakra-ui/react"

interface IProject extends PageProps {}

interface IContentfulProject {
    name: string;
    excerpt: string;
    website: string;
    logo: {
        title: string;
        file: {
            url: string;
        }
    };
    screens: Array<{
        title: string;
        file: {
            url: string;
        }
    }>;
    stack: Array<string>;
    longDescription: {
        longDescription: string;
    }
}

const Project: React.FC<IProject> = ({data}) => {

    // @ts-expect-error
    const current: IContentfulProject = data.contentfulProject;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [index, setIndex] = useState(0)
    const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)")

    const openModel = (i: number): void => {
        if (isLargerThan1280) {
            setIndex(i);
            onOpen();
        }
    }

    const closeModel = (): void => {
        setIndex(0);
        onClose();
    }

    return (
        <Layout title={current.name} icon="🍕">
            <Flex as="section"
                flexDirection="column"
                placeContent="center"
                paddingTop={6}
                paddingBottom={6}
                paddingLeft={3}
                paddingRight={3}
                width="1080px"
                maxWidth="100%"
                minHeight="100vh"
                marginLeft="auto"
                marginRight="auto"
            >
                <Flex marginBottom={2}>
                    <Image src={current.logo.file.url} boxSize={28} alt={current.logo.title}
                        marginRight={2}
                        borderRadius="lg" />
                    <div>
                        <Heading as="h1"
                            fontSize={{base: '4xl', md: '7xl'}}
                            lineHeight="0.95"
                        >
                            {current.name}
                        </Heading>
                        <Text as="p"
                            fontSize={{base: 'xl', md: '3xl'}}
                        >
                            {current.excerpt}
                        </Text>
                    </div>
                </Flex>
                <Divider></Divider>
                <Heading as="h2"
                    fontSize="clamp(calc(24px * 0.75), calc((calc(16px + 2vw)) * 0.75), calc(40px * 0.75))"
                    marginBottom={2}
                    marginTop={4}
                >
                    Technology stack
                </Heading>
                <SimpleList items={current.stack} />
                <Heading as="h2"
                    fontSize="clamp(calc(24px * 0.75), calc((calc(16px + 2vw)) * 0.75), calc(40px * 0.75))"
                    marginBottom={2}
                    marginTop={4}
                >
                    Screens
                </Heading>
                <Grid
                    templateColumns={{ base: "repeat(1, minmax(0, 1fr))", md: "repeat(3, minmax(0, 1fr))" }}
                    gridRowGap={6}
                    gridColumnGap={8}
                    placeContent="center"
                >
                    {
                        current.screens
                            .map((screen, i) => (
                                <Tooltip hasArrow
                                key={i}
                                label="Click to zoom"
                                bg="gray.300"
                                color="black"
                                placement="bottom-end"
                                >
                                    <AspectRatio key={i} ratio={16 / 9} onClick={() => openModel(i)}>
                                        <Image src={screen.file.url} alt={screen.title} objectFit="cover" />
                                    </AspectRatio>
                                </Tooltip>
                            ))
                    }
                    {
                        current.name === 'DocuGen' && <AspectRatio ratio={16 / 9}>
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/HyODAyL8BCs?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </AspectRatio>
                    }
                    {
                        current.name === 'DocuGen' && <AspectRatio ratio={16 / 9}>
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/jE1NRbreLOE?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </AspectRatio>
                    }
                </Grid>
                    

                <Heading as="h2"
                    fontSize="clamp(calc(24px * 0.75), calc((calc(16px + 2vw)) * 0.75), calc(40px * 0.75))"
                    marginTop={6}
                >
                    My role in {current.name}
                </Heading>
                <Text marginTop={2} fontSize="lg">
                    {current.longDescription.longDescription}
                </Text>
                <Flex marginTop={2} justifyContent="flex-end">
                    {current.name === 'DocuGen' && <Link 
                    href="https://auth.monday.com/oauth2/authorize?client_id=480dcea88a995b174b92919601ac4e18&response_type=install" isExternal _hover={{
                        textDecoration: 'none'
                    }}>
                        <Button borderRadius="3xl" margin={3}>
                        Add to monday.com<ExternalLinkIcon mx="8px" />
                        </Button>
                    </Link>}
                    <Link href={`${current.website}`} isExternal _hover={{
                        textDecoration: 'none'
                    }}>
                        <Button borderRadius="3xl" margin={3}>
                            Visit website<ExternalLinkIcon mx="8px" />
                        </Button>
                    </Link>
                </Flex>
            </Flex>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="6xl"
            >   
                <ModalOverlay />
                <ModalBody>
                    <ModalContent>
                        <Grid paddingTop={6} gridTemplateColumns="auto 1fr auto" gridColumnGap={4} alignSelf="center" alignItems="center">
                        <Button marginX={2} onClick={() => setIndex(index-1)} disabled={index === 0 ? true : false}>
                            <ArrowBackIcon boxSize={10} />
                        </Button>
                        <Image borderRadius={10} src={current.screens[index].file.url} alt={"Just testing"} objectFit="cover" />
                        <Button marginX={2} onClick={() => setIndex(index+1)} disabled={index === current.screens.length - 1 ? true : false}>
                            <ArrowForwardIcon boxSize={10}/>
                        </Button>
                        </Grid>    

                        <ModalFooter>
                            <Button  mr={3} onClick={closeModel}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </ModalBody>
            </Modal>
        </Layout>
    )
}

export default Project

export const query = graphql`
    query getProject($slug: String!) {
        contentfulProject(slug:  {eq: $slug}) {
            name
            excerpt
            website
            logo {
            title
            file {
                    url
                }
            }
            screens {
            title
            file {
                    url
                }
            }
            stack
            longDescription {
                longDescription 
            }
        }
    }
`
